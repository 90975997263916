import React from "react";
import DefaultLayout from "../components/layout/DefaultLayout";

const ProductPage = () =>{
    return (<DefaultLayout>
        ProductPage
        <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non esse ut doloribus veritatis repellat iste, dolores et, illum, in cum architecto aperiam dignissimos hic eaque nihil amet vero iusto aliquid!
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non esse ut doloribus veritatis repellat iste, dolores et, illum, in cum architecto aperiam dignissimos hic eaque nihil amet vero iusto aliquid!
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non esse ut doloribus veritatis repellat iste, dolores et, illum, in cum architecto aperiam dignissimos hic eaque nihil amet vero iusto aliquid!
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non esse ut doloribus veritatis repellat iste, dolores et, illum, in cum architecto aperiam dignissimos hic eaque nihil amet vero iusto aliquid!
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non esse ut doloribus veritatis repellat iste, dolores et, illum, in cum architecto aperiam dignissimos hic eaque nihil amet vero iusto aliquid!
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non esse ut doloribus veritatis repellat iste, dolores et, illum, in cum architecto aperiam dignissimos hic eaque nihil amet vero iusto aliquid!
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non esse ut doloribus veritatis repellat iste, dolores et, illum, in cum architecto aperiam dignissimos hic eaque nihil amet vero iusto aliquid!
        </p>
        </DefaultLayout>);
};
export default ProductPage;